html,
body,
#root {
    height: 100%;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

* {
    box-sizing: border-box;
}

#page-takeover {
    display: none;
}

/* Freestar ads - shared styles */
#tsr_header_leaderboard,
#tsr_thread_firstpost,
#tsr_thread_reply_1,
#tsr_thread_reply_2,
#tsr_thread_reply_3,
#tsr_thread_reply_4,
#tsr_thread_reply_nth_1,
#tsr_thread_reply_nth_2,
#tsr_thread_reply_nth_3,
#tsr_thread_reply_nth_4,
#tsr_sidebar_1,
#tsr_sidebar_2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

/* Freestar ads - ad unit specific heights for CLS */
#tsr_header_leaderboard {
    min-height: 50px;
}
#tsr_thread_firstpost,
#tsr_thread_reply_1,
#tsr_thread_reply_2,
#tsr_thread_reply_3,
#tsr_thread_reply_4,
#tsr_thread_reply_nth_1,
#tsr_thread_reply_nth_2,
#tsr_thread_reply_nth_3,
#tsr_thread_reply_nth_4
{
    min-height: 280px;
}

@media only screen and (min-width: 768px) {
    #tsr_sidebar_1 {
        min-height: 600px;
    }
    #tsr_sidebar_2 {
        min-height: 250px;
    }
    /* TODO remove when we deploy ownership blocks */
    #roadblock {
        min-height: 250px !important;
    }
    #tsr_header_leaderboard,
    #tsr_thread_firstpost,
    #tsr_thread_reply_1,
    #tsr_thread_reply_2,
    #tsr_thread_reply_3,
    #tsr_thread_reply_4,
    #tsr_thread_reply_nth_1,
    #tsr_thread_reply_nth_2,
    #tsr_thread_reply_nth_3,
    #tsr_thread_reply_nth_4 {
        min-height: 90px;
    }
}
